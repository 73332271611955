import { Link as BaseLink, RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import styled from 'styled-components';

import { PublicRoute } from '../components/PublicRoute';
import { SEO } from '../components/Seo';
import { H1, Typography } from '../components/Typography';
import { Device } from '../utils/media-queries-utils';
import { Colors } from '../utils/style-utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px 220px;

  @media ${Device.laptopM} {
    padding: 100px 150px;
  }

  @media ${Device.laptop} {
    padding: 100px 75px;
  }

  @media ${Device.tablet} {
    padding: 100px 30px;
  }

  @media ${Device.mobileS} {
    padding: 100px 30px;
  }
`;

const Paragraph = styled(Typography)`
  font-size: 12pt;
  line-height: 1.75;
  margin-bottom: 1rem;
`;

const Link = styled(BaseLink)`
  color: ${Colors.Blue500};
`;

const Cost: FC<RouteComponentProps> = (props) => {
  return (
    <PublicRoute {...props}>
      <SEO
        title="User Guide"
        description="An overview of how Hypercite works, what it's for and frequently asked questions about Hypercite and its usage"
      />
      <Container>
        <H1 className="mb-24" color="#051392">
          How much does Hyper(Cite)<sup>&reg;</sup> cost?
        </H1>

        <Paragraph>
          Hyper(Cite)<sup>&reg;</sup> is currently free to use. We do not collect data or sell data to third parties to
          obtain income. The software is free to use so that it gains wider adoption. Requiring payment at this point
          does not make sense until the software gains wider adoption.
        </Paragraph>

      </Container>
    </PublicRoute>
  );
};

export default Cost;
